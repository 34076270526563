import React, { useEffect, useState, useRef, useMemo } from 'react'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import GenericChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, {  } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import MUIDataTable from 'mui-datatables'
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import { metricColors } from '../../../helpers/constants'
import { sanitizeSleepData, sanitizeSleepQualityData } from '../../../helpers/misc-functions'
import HighchartsReact from 'highcharts-react-official'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemSecondaryAction
} from '@mui/material'


  interface IProps {
    selectedUser: IUserInfo | null
    userDetailData: IUserDetailData | null
    panelType: PanelType
    filterType: IChartFilter
    showDailyFilter: boolean
  }

  interface DataPoint {
    timestamp: string;
    stress: number;
  }

  interface TimeRange {
    start: string;
    end: string;
    startDate: string,
    endDate: string;
    average: number;
    plotBandId: string;
    dateIdentifier: string;
  }

  interface TimestampData {
    time: string;
    fullDate: Date;
  }
  
  const defaultChartOptions = {
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: '',
        data: [],
      },
    ],
  }


  const GenericPanelContent: React.FC<IProps> = ({ selectedUser, userDetailData, panelType, filterType, showDailyFilter}) => {
    const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>('weekly')
    const [chartOptions, setChartOptions] = useState<any>(defaultChartOptions)
    const [dailyArrIndex, setDailyArrIndex] = useState<number>(0);
    const [weeklyArrStartIndex, setWeeklyArrStartIndex] = useState<number>(0);
    const [weeklyArrEndIndex, setWeeklyArrEndIndex] = useState<number>(0);
    const [formattedDailyArr, setFormattedDailyArr] = useState<any>([]);
    const [formattedWeeklyArr, setFormattedWeeklyArr] = useState<any>([]);
    const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
    const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)
    const [displayLeftArrow, setDisplayLeftArrow] = useState<boolean | null>(false);
    const [displayRightArrow, setDisplayRightArrow] = useState<boolean | null>(false);
    const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
    const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)
    const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
    const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)
    const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)
    const [tableData, setTableData] = useState<any[]>([])

    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const [ranges, setRanges] = useState<TimeRange[]>([])

  
    // These state variables belong to the custom filter
    const [startDateValue, setStartDateValue] = useState<Date | any>(null)
    const [endDateValue, setEndDateValue] = useState<Date | any>(null)
    const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
    const [avgType, setAvgType] = useState<string | null>('')
    const [btnPressed, setBtnPressed] = useState<boolean | null>(null)

    const [chartInstance, setChartInstance] = useState<Highcharts.Chart | null>(null);


    // Number of days to show on weekly chart
    const numOfWeeklyDays = 28
    const MAX_ITEMS_PER_COLUMN = 1;
    let tableColumns: any[] = []
    let tableOptions: any = {}


  // Calculate average stress for a given time range
  const calculateAverage = (data: any, start: number, end: number): number => {
    const relevantData = data.slice(start, end + 1);
    return relevantData.reduce((acc: any, point: any) => acc + point, 0) / relevantData.length;
  };

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  // Handle clearing all chart selections
  const clearRangeSelections = () => {

    if (chartInstance) {
      
      // Remove each plot band by its ID
      ranges.forEach(range => {
        try {
          chartInstance.xAxis[0].removePlotBand(range.plotBandId);
        } catch (error) {
          console.error('Error clearing all plot bands: ', error)
        }
        
      });
    }
    setRanges([]);
  };

  const removeRange = (plotBandId: string) => {
    if (chartInstance) {
      try {
        chartInstance.xAxis[0].removePlotBand(plotBandId);
      } catch (error) {
        console.error('Error removing plot band: ', error)
      }      
    }
    setRanges(prev => prev.filter(range => range.plotBandId !== plotBandId));
  };

  // We'll need to organize our ranges into columns
  const organizeRangesIntoColumns = (ranges: TimeRange[]) => {
    const columns: TimeRange[][] = [];
    let currentColumn: TimeRange[] = [];
    
    ranges.forEach((range, index) => {
      if (currentColumn.length >= MAX_ITEMS_PER_COLUMN) {
        columns.push(currentColumn);
        currentColumn = [];
      }
      currentColumn.push(range);
    });
    
    // Don't forget to add the last column
    if (currentColumn.length > 0) {
      columns.push(currentColumn);
    }

    // console.log("organized columns: ", columns)
    // console.log("current column: ", currentColumn)
    
    return columns;
  };


    if (panelType === 'womens-health'){
      tableColumns = [
        { name: 'Due Date', options: { filter: false, sort: true } },
        { name: 'Original Due Date', options: { filter: false, sort: true } },
        { name: 'Period Length', options: { filter: false, sort: true } },
        { name: 'Current Phase', options: { filter: false, sort: true } },
        { name: 'Current Phase Type', options: { filter: false, sort: true } },
        { name: 'Length Of Current Phase', options: { filter: false, sort: true } },
        { name: 'Cycle Length', options: { filter: false, sort: true } },
        { name: 'Predicted Cycle Length', options: { filter: false, sort: false } },
        { name: 'Is Predicted Cycle', options: { filter: false, sort: true } },
        { name: 'Pregnancy Cycle Start Date', options: { filter: false, sort: true } },
      ]
      tableOptions = {
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    } else if (panelType === 'blood-pressure'){

      tableColumns = [
        { name: 'Measurement Time', options: { filter: false, sort: true } },
        { name: 'Systolic', options: { filter: false, sort: true } },
        { name: 'Diastolic', options: { filter: false, sort: true } },
        { name: 'Source Type', options: { filter: false, sort: true } },
        { name: 'Pulse', options: { filter: false, sort: true } },
        { name: 'MAP', options: { filter: false, sort: true } }
      ]
    
      tableOptions = {
        textLabels: {
          body: {
            noMatch: "No data available for the date selected",
          },
        },
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    } else if (panelType === 'sleep'){
      tableColumns = [
        { name: 'Date', options: { filter: false, sort: true } },
        { name: 'Total Sleep', options: { filter: false, sort: true } },
        { name: 'Deep', options: { filter: false, sort: true } },
        { name: 'Light', options: { filter: false, sort: true } },
        { name: 'REM', options: { filter: false, sort: true } },
        { name: 'Awake', options: { filter: false, sort: true } },
        { name: 'Sleep Quality Score', options: { filter: false, sort: true } }
      ]

      tableOptions = {
        textLabels: {
          body: {
            noMatch: "No data available for the date selected",
          },
        },
        viewColumns: false,
        filter: false,
        elevation: 0,
        selectableRows: 'none' as 'none',
        setTableProps: () => {
          return {
            size: 'small',
          }
        },
      }
    }


    // This is for checking the type of the data coming in when needed
    function isDailyHeartRateData(data: any): data is HeartRateData {
      return typeof data === 'object' && data !== null && 'timeoffsetheartratesamples' in data;
    }
    function isO2Data(data: any): data is O2Data {
      return typeof data === 'object' && data !== null && 'timeoffsetsleepspo2' in data;
    }
    function isStress(data: any): data is StressData {
      return typeof data === 'object' && data !== null && 'timeoffsetstresslevelvalues' in data;
    }
    function isBodyBattery(data: any): data is BodyBatteryData {
      return typeof data === 'object' && data !== null && 'timeoffsetbodybatteryvalues' in data;
    }
    function isIntensityMinutes(data: any): data is IntensityMinutesData {
      return typeof data === 'object' && data !== null && 'vigorousintensitydurationinseconds' in data && 'moderateintensitydurationinseconds' in data;
    }
    function isSleep(data: any): data is SleepData {
      return typeof data === 'object' && data !== null && 'sleeplevelsmap' in data;
    }
    function isStepsData(data: any): data is Steps {
      return data !== null && typeof data !== "string";
    }
    function isBloodPressure(data: any): data is BloodPressureMeasurement[] {
      return Array.isArray(data) && data !== null;
    }  
    function isInteger(value: any): boolean {
      return typeof value === 'number' && Number.isInteger(value);
  }

  const handleGetNext = () => {
    
    let lengthOfDataArr = 0;
    if (userDetailData) {

      // Using heart_rate daily since all data summaries have same
      // amount of days in their daily arrays
      lengthOfDataArr = Object.keys(userDetailData.heart_rate.daily).length            
    

      if (selectedFilter === 'daily') {

        if ((dailyArrIndex + 1) <= lengthOfDataArr - 1) {
          setDailyArrIndex(dailyArrIndex + 1);
          setDisplayRightArrow(true);
        } else {
          setDisplayRightArrow(false);
          console.log("Reached end of data set")
        }
      } else if (selectedFilter === 'weekly') {

        if ((weeklyArrEndIndex + numOfWeeklyDays) <= lengthOfDataArr - 1) {
          setWeeklyArrEndIndex(weeklyArrEndIndex + numOfWeeklyDays);
          setWeeklyArrStartIndex(weeklyArrStartIndex + numOfWeeklyDays);
          setDisplayRightArrow(true)
        } else {
          setWeeklyArrEndIndex(lengthOfDataArr - 1);
          setWeeklyArrStartIndex((lengthOfDataArr - 1) - numOfWeeklyDays)
          setDisplayRightArrow(false)
          console.log("Reached end of data set")
        }
      }
    }
  }

  const handleGetPrevious = () => {

    if (userDetailData) {

        if (selectedFilter === 'daily') {
            if ((dailyArrIndex - 1) >= 0) {
                setDailyArrIndex(dailyArrIndex - 1);
                setDisplayLeftArrow(true);
            }
            else {
                setDisplayLeftArrow(false);
                console.log("Reached beginning of data set")
            }
    
        } else if (selectedFilter === 'weekly') {
            if ((weeklyArrStartIndex - numOfWeeklyDays) >= 0) {
                setWeeklyArrStartIndex(weeklyArrStartIndex - numOfWeeklyDays);
                setWeeklyArrEndIndex(weeklyArrEndIndex - numOfWeeklyDays);            
                setDisplayLeftArrow(true)
            } else {
                setWeeklyArrStartIndex(0);
                setWeeklyArrEndIndex(numOfWeeklyDays);            
                setDisplayLeftArrow(false)
                console.log("Reached end of data set")
            }
        }
    }
  }


    // Get a unique identifier for the current day's data
    const currentDateIdentifier = useMemo(() => {
      
      let result = singleDayArrowsUsed || new Date().toISOString().split('T')[0];

      return result
    }, [singleDayArrowsUsed]);
  

    ////
    // UseEffects
    ////
    
    // This sets the filter for all charts and resets any plotbands created
    useEffect(() => {
    
      // Initialize chart filters
      if (selectedUser && !selectedFilter && panelType!=='blood-pressure') {
      setSelectedFilter('weekly')
      } else if (selectedUser && panelType === 'blood-pressure'){
        setSelectedFilter('daily')
      }

      // Reset/clear plotbands
      clearRangeSelections()
            
    }, [selectedUser])



    // Update plot bands whenever ranges change
    useEffect(() => {

      // First ensure both chart instance and valid axis exist
      if (!chartInstance?.xAxis?.[0]?.categories) {
        // console.log("Chart not fully initialized:", {
        //   hasInstance: !!chartInstance,
        //   hasXAxis: !!chartInstance?.xAxis,
        //   hasCategories: !!chartInstance?.xAxis?.[0]?.categories
        // });
        return;
      }

      // Clear all existing plot bands by their IDs to prevent darkening effect
      ranges.forEach(range => {
        chartInstance.xAxis[0].removePlotBand(range.plotBandId);
      });
      
      // Add plot bands for each range
      ranges.forEach((range, idx) => {

        if (range.dateIdentifier === currentDateIdentifier) {
          try {
            const startIndex = chartInstance.xAxis[0].categories.indexOf(range.start);
            const endIndex = chartInstance.xAxis[0].categories.indexOf(range.end);
      
      
            // console.log("Adding plot band:", {
            //   start: range.start,
            //   end: range.end,
            //   startIndex,
            //   endIndex,
            //   categories: chartInstance.xAxis[0].categories
            // });
      
            if (startIndex !== -1 && endIndex !== -1) {
              
              chartInstance.xAxis[0].addPlotBand({
                from: startIndex - 0.5,
                to: endIndex + 0.5,
                color: 'rgba(33, 150, 243, 0.15)',
                id: range.plotBandId,
                label: {
                  text: `Avg: ${range.average.toFixed(1)}`,
                  style: {
                    color: '#1976d2',
                    fontWeight: 'bold'
                  },
                  y: 20
                }
              })
            } 
          }    
          catch (error) {
            console.error('Error adding plot bland: ', error)
          }
        }
      });
      
    }, [ranges, chartInstance, currentDateIdentifier]);

    
    // This preps the data for the panels
    useEffect(() => {
        if (userDetailData) {

          let dailyData: any[] = []

          if (panelType === 'heartrate'){
              // Use this function when mapping data
              dailyData = Object.keys(userDetailData.heart_rate.daily).map(key => {
                  const data = userDetailData.heart_rate.daily[key];
                  return { date: key,
                      data: isDailyHeartRateData(data) ? data : null };
              });
            } else if(panelType === 'o2-sats'){
              dailyData = Object.keys(userDetailData.o2.daily).map(key => {
                const data = userDetailData.o2.daily[key];
                return { date: key, data: isO2Data(data) ? data : null };
              });

            } else if(panelType === 'stress'){
              dailyData = Object.keys(userDetailData.stress.daily).map(key => {
                const data = userDetailData.stress.daily[key];
                return { date: key, data: isStress(data) ? data : null };
              });

            } else if(panelType === 'body-battery'){

              dailyData = Object.keys(userDetailData.body_battery.daily).map(key => {
                const data = userDetailData.body_battery.daily[key];
                return { date: key, data: isBodyBattery(data) ? data : null };
              });        

            } else if(panelType === 'intensity-minutes') {
              dailyData = Object.keys(userDetailData.intensity.daily).map(key => {
                const data = userDetailData.intensity.daily[key];
                return { date: key, data: isIntensityMinutes(data) ? data : null };
              });

            } else if(panelType === 'sleep') {
              dailyData = Object.keys(userDetailData.sleep.daily).map(key => {
                const data = userDetailData.sleep.daily[key];
                return { date: key, data: isSleep(data) ? data : null };
              });

            } else if(panelType === 'womens-health'){

            } else if(panelType === 'steps') {
              dailyData = Object.keys(userDetailData.steps.daily).map(key => {
                const data = userDetailData.steps.daily[key];
                return { date: key, data: isStepsData(data) ? data : null };
              });        

            } else if (panelType === 'blood-pressure'){
              dailyData = Object.keys(userDetailData.blood_pressure.daily).map(key => {
                let data = null;        
                if (userDetailData.blood_pressure.daily[key] !== "-") {
                  data = userDetailData.blood_pressure.daily[key]
                }
                return { date: key, data: data };
              });

            } else {
              console.error("Unsupported panel type provided: ", panelType)
          }

          if (dailyData.length !== 0){
            const selectedDayIndex = dailyData.length - 1
            const selectedDailyDate = dailyData[selectedDayIndex].date
            const selectedWeekEndIndex = dailyData.length - 1
            const selectedWeekStartIndex = selectedWeekEndIndex - numOfWeeklyDays    
    
            // Adding 1 since the slice method is exclusive
            const weeklyData = dailyData.slice(selectedWeekStartIndex, selectedWeekEndIndex + 1)
    
            // Set up daily data
            setFormattedDailyArr(dailyData)
            setDailyArrIndex(selectedDayIndex)
    
            // This is for the display of the date in the panel for daily filter
            setSingleDayArrowsUsed(selectedDailyDate)
    
            // Set up weekly data
            setFormattedWeeklyArr(weeklyData)
            setWeeklyArrStartIndex(selectedWeekStartIndex)
            setWeeklyArrEndIndex(selectedWeekEndIndex)

          } else {
            console.debug("INFO: No daily data provided")
          }


        }
    }, [userDetailData])

    // Setting up charts for non 'custom' filters
    useEffect(() => {

        if (userDetailData) {

            if (!selectedFilter || formattedDailyArr.length === 0 || dailyArrIndex === null
            ) {
                setChartOptions(defaultChartOptions) 

            } else if (selectedFilter === 'daily') {

                if (panelType === 'heartrate'){

                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                    const categories = isDailyHeartRateData(dailyData)
                    ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) =>
                        new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                    : [];
    
                    const series = isDailyHeartRateData(dailyData)
                    ? Object.values(dailyData.timeoffsetheartratesamples).map((item: any) => item?.value)
                    : [];
    
                    const chartDataForDaily = {
                    ...defaultChartOptions,
                    xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                    },
                    series: [
                        {
                        name: 'Heartrate',
                        data: series,
                        color: metricColors.heartRate.resting
                        },
                    ],
                    }
    
                    setChartOptions(chartDataForDaily)
                  } else if(panelType === 'o2-sats'){
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                    const categories = isO2Data(dailyData)
                    ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) =>
                      new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                    : [];
          
                    const series = isO2Data(dailyData)
                      ? Object.values(dailyData.timeoffsetsleepspo2).map((item: any) => item?.value)
                      : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                      },
                      series: [
                        {
                          name: 'O2 Sats',
                          data: series,
                          color: metricColors.o2.average
                        },
                      ],
                    }
                    setChartOptions(chartDataForDaily)          

                  } else if(panelType === 'stress'){
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)

                    const categories = isStress(dailyData)
                    ? Object.values(dailyData.timeoffsetstresslevelvalues).map((item: any) =>
                      new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                    : [];

                    const series = isStress(dailyData)
                    ? Object.values(dailyData.timeoffsetstresslevelvalues).map((item: any) => item?.value)
                    : [];

                    const timestamps = isStress(dailyData)
                    ? Object.values(dailyData.timeoffsetstresslevelvalues).map((item: any) => {
                        const date = new Date(parseInt(item?.timestamp) * 1000);
                        return {
                          time: date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                          fullTimestamp: date
                        };
                      })
                    : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: timestamps.map(t => t.time)
                      },
                      chart: {
                        type: 'line',
                        // Enable horizontal selection with zoomType
                        zoomType: 'x',
                        // Handle the selection event
                        events: {
                          load: function(this: Highcharts.Chart) {
                            console.log("Chart loaded")
                            setChartInstance(this)
                          },
                          selection(this: Highcharts.Chart, e: Highcharts.ChartSelectionContextObject) {
                            if (e.xAxis) {
                              // Get the selected range boundaries

                              const leftEdgeOfSelection = e.xAxis[0].min
                              const rightEdgeOfSelection = e.xAxis[0].max

                              const startIndex = Math.max(0, Math.min(Math.round(leftEdgeOfSelection), series.length - 1));
                              const endIndex = Math.max(0, Math.min(Math.round(rightEdgeOfSelection), series.length - 1));

                              const selectedStartData = timestamps[startIndex];
                              const selectedEndData = timestamps[endIndex];
                              
                              
                              // Create a unique identifier for this selection
                              const plotBandId = `range-${Date.now()}`;
                              
                              // Calculate the average for selected range
                              const average = calculateAverage(series, startIndex, endIndex);
                              
                              // Add the new range to our state
                              const newRange = {
                                start: selectedStartData.time,
                                end: selectedEndData.time,
                                startDate: formatDate(selectedStartData.fullTimestamp),
                                endDate: formatDate(selectedEndData.fullTimestamp),
                                average,
                                plotBandId,
                                dateIdentifier: currentDateIdentifier
                              };
                              
                              setRanges(prev => [...prev, newRange]);
                              
                            }
                            return false; // Prevent zooming behavior
                          }
                        }
                      },
                      plotOptions: {
                        line: {
                          marker: {
                            enabled: true,
                            radius: 4,
                            symbol: 'circle',
                            states: {
                              hover: {
                                enabled: true,
                                lineWidth: 2
                              }
                            }
                          }
                        }
                      },
                      series: [
                        {
                          name: 'Stress',
                          data: series,
                          color: metricColors.stress.average
                        },
                      ],
                    }        
            
                    setChartOptions(chartDataForDaily)
      
                  } else if(panelType === 'body-battery'){  
                    const dailyData = formattedDailyArr[dailyArrIndex]?.data;
                    const dailyDate = formattedDailyArr[dailyArrIndex]?.date;
                    setSingleDayArrowsUsed(dailyDate)                  
                      const categories = isBodyBattery(dailyData)
                      ? Object.values(dailyData.timeoffsetbodybatteryvalues).map((item: any) =>
                        new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
                      : [];
            
                    const series = isBodyBattery(dailyData)
                      ? Object.values(dailyData.timeoffsetbodybatteryvalues).map((item: any) => item?.value)
                      : [];
            
                    const chartDataForDaily = {
                      ...defaultChartOptions,
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories
                      },
                      series: [
                        {
                          name: 'Body Battery',
                          data: series,
                          color: metricColors.stress.average
                        },
                      ],
                    }         
            
                    setChartOptions(chartDataForDaily)
      
                  } else if(panelType === 'intensity-minutes') {
                    // setShowDailyFilter(false)
                  } else if(panelType === 'sleep') {
                    // setShowDailyFilter(false)
                  } else if(panelType === 'womens-health'){
                    // setShowDailyFilter(false)
                    const womensHealth = userDetailData.women
                    const data: any[] = []
              
                    let isPredictedCycle: any = String(womensHealth.ispredictedcycle)
                    if (womensHealth.ispredictedcycle === true) {
                      isPredictedCycle = <CheckCircleIcon color="success" />
                    } else if (womensHealth.ispredictedcycle === false) {
                      isPredictedCycle = <CancelIcon color="error" />
                    }
              
                    data.push([
                      womensHealth.duedate,
                      womensHealth.originalduedate,
                      womensHealth.periodlength,
                      womensHealth.currentphase,
                      womensHealth.currentphasetype,
                      womensHealth.daysuntilnextphase,
                      womensHealth.cyclelength,
                      womensHealth.predictedcyclelength,
                      womensHealth.ispredictedcycle,
                      womensHealth.pregnancycyclestartdate
                    ])
                    setTableData(data)
      
                  } else if(panelType === 'steps') {
                    // setShowDailyFilter(false)
                  } else if (panelType === 'blood-pressure'){
                    // setShowDailyFilter(true)            
                    const data: any[] = []
                    let localMeasurementTime;
            
                    if (formattedDailyArr) {
                      formattedDailyArr.forEach((item: any) => {                        
                        if (item.data){                          
                          if (isInteger(item.data[0].measurementtime)){
                            const measurementTimeInMilli = item.data[0].measurementtime * 1000 

                            localMeasurementTime = new Date(measurementTimeInMilli).toLocaleString('en-US', {
                              year: 'numeric',    // "2024"
                              month: 'short',     // "Jan"
                              day: 'numeric',     // "1"
                              hour: 'numeric',    // "12"
                              minute: '2-digit',  // "00"
                              hour12: true        // Use AM/PM
                          });
                          

                          } else {
                            localMeasurementTime = item.data[0].measurementtime
                          }
                            data.push([
                              localMeasurementTime,
                              item.data[0].systolic,
                              item.data[0].diastolic,
                              item.data[0].sourceType,
                              item.data[0].pulse,
                              item.data[0].mapformula
                            ])                          
                        }      
                      })
                    }
                    setTableData(data)
                  } else {
                    setTableData([])
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }


            } else if (selectedFilter === 'weekly' && weeklyArrStartIndex !== null && weeklyArrEndIndex !== null) {
                const weeklyData = formattedDailyArr.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1)

                // This is for the display of hte dates in the panel for weekly filter
                setFilterButtonStartDate(weeklyData[0].date)
                setFilterButtonEndDate(weeklyData[weeklyData.length - 1].date)

                if (panelType === 'heartrate'){
                    const categories = Object.values(weeklyData).map((item: any) => item.date)
                    const seriesAvgHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.averageheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const seriesMaxHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.maxheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const seriesRestingHeartrate = Object.values(weeklyData).map((item: any) => {
                        if (item.data) {
                            return item?.data?.restingheartrateinbeatsperminute
                        } else {
                            return null
                        }
                        })
        
                        const multidayChartData = {
                        title: {
                            text: '',
                        },
                        plotOptions: {
                            series: {
                            label: {
                                connectorAllowed: false,
                            }
                            },
                        },
                        xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                        },
                        series: [
                            {
                            name: 'Avg HR',
                            data: seriesAvgHeartrate,                     
                            color: metricColors.heartRate.average,
                            },
                            {
                            name: 'Rest HR',
                            data: seriesRestingHeartrate,
                            color: metricColors.heartRate.resting
                            },
                            {
                            name: 'Max HR',
                            data: seriesMaxHeartrate,
                            color: metricColors.heartRate.max
                            },
                        ],
                        }
                        setChartOptions(multidayChartData)
                      } else if(panelType === 'o2-sats'){

                        const categories = Object.values(weeklyData).map((item: any) => item.date)

                        const seriesLowO2 = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.low
                          } else {
                            return null
                          }
                        })
                
                        const seriesAvgO2 = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.avg
                          } else {
                            return null
                          }
                        })
                
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Low',
                              data: seriesLowO2,
                              color: metricColors.o2.low
                            },
                            {
                              name: 'Average',
                              data: seriesAvgO2,
                              color: metricColors.o2.average
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)

                      } else if(panelType === 'stress'){
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesAvgStressLevel = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.averagestresslevel
                          } else {
                            return null
                          }
                        })

                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Avg Stress',
                              data: seriesAvgStressLevel,
                              color: metricColors.stress.average
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)
          
                      } else if(panelType === 'body-battery'){
                              
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesBodyBatteryHigh = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.high
                          } else {
                            return null
                          }
                        })
                        const seriesBodyBatteryLow = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.low
                          } else {
                            return null
                          }
                        })
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'High',
                              data: seriesBodyBatteryHigh,
                              color: metricColors.bodyBattery.high
                            },
                            {
                              name: 'Low',
                              data: seriesBodyBatteryLow,
                              color: metricColors.bodyBattery.low
                            }
                          ],
                        }
                        setChartOptions(multidayChartData)
                
                      } else if(panelType === 'intensity-minutes') {
                        
                        const categories = Object.values(weeklyData).map((item: any) => item.date)
                        const seriesIntensityMinutesVigorous = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.vigorousintensitydurationinseconds
                          } else {
                            return null
                          }
                        })
                        const seriesIntensityMinutesModerate = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.moderateintensitydurationinseconds
                          } else {
                            return null
                          }
                        })


                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Vigorous',
                              data: seriesIntensityMinutesVigorous,
                              color: metricColors.activity.vigorousIntensity
                            },
                            {
                              name: 'Moderate',
                              data: seriesIntensityMinutesModerate,
                              color: metricColors.activity.moderateIntensity
                            }
                          ],
                        }
                        setChartOptions(multidayChartData)
                      } else if(panelType === 'sleep') {

                        const categories = Object.values(weeklyData).map((item: any) => item.date)                
                        const seriesDeepSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.deepsleepdurationinseconds != "-") {
                            return { 
                              y: item?.data?.deepsleepdurationinhours,
                              additionalField: item?.data?.deepsleepduration
                            }          
                
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                
                        const seriesLightSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.lightsleepdurationinseconds !== "-") {
                            return { 
                              y: item?.data?.lightsleepdurationinhours,
                              additionalField: item?.data?.lightsleepduration
                            } 
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                        const seriesREMSleep = Object.values(weeklyData).map((item: any) => {
                          if (item.data && item.data.remsleepdurationinseconds !== "-") {
                            return { 
                             y: item?.data?.remsleepdurationinhours,
                             additionalField: item?.data?.remsleepduration
                            }
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })
                        const seriesAwakeDuration = Object.values(weeklyData).map((item: any) => {
                
                          if (item.data && item.data.awakedurationinseconds !== "-") {
                
                            return { 
                              y: item?.data?.awakedurationinhours,
                              additionalField: item?.data?.awakeduration
                            }
                          } else {
                            return { 
                              y: 0,
                              additionalField: "-"
                            }   
                          }
                        })

                        const seriesSleepQuality = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data?.sleepquality
                          } else {
                            return null
                          }
                        })                                  
                        
                        const multidaySleepChartData = {
                          title: {
                            text: 'Sleep Hours & Quality',
                          },
                          chart: {
                            type: 'column',
                          },
                          plotOptions: {
                            column: {
                              stacking: 'normal',
                              dataLabels: {
                                enabled: false,
                              },
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          yAxis: [{
                            min: 0,
                            title: {
                              text: 'Hours'
                            }
                          }, {
                            title: {
                              text: 'Quality'
                            },
                            opposite: true,
                            min: 0,
                            max: 100
                          }],
                          legend: {
                            shadow: false
                          },
                          tooltip: {
                            formatter: function(this: Highcharts.TooltipFormatterContextObject): string {
                              const value = this.y;
                              const additionalData = (this.point as any).additionalField;
                              if (typeof value !== 'number') {
                                return `${this.series.name}: N/A`;
                              }
                              if (this.series.name === 'Quality') {
                                return `${this.series.name}: ${value.toFixed(2)}%`;
                              }
                              return `${this.series.name}: ${additionalData}`;
                            }
                          },
                          series: [
                            {
                              name: 'Deep',
                              data: sanitizeSleepData(seriesDeepSleep, 'Deep'),
                              stack: 'hours',
                              color: metricColors.sleep.deep
                            },
                            {
                              name: 'Light',
                              data: sanitizeSleepData(seriesLightSleep, 'Light'),
                              stack: 'hours',
                              color: metricColors.sleep.light
                            },
                            {
                              name: 'REM',
                              data: sanitizeSleepData(seriesREMSleep, 'REM'),
                              stack: 'hours',
                              color: metricColors.sleep.rem
                            },
                            {
                              name: 'Awake',
                              data: sanitizeSleepData(seriesAwakeDuration, 'Awake'),
                              stack: 'hours',
                              color: metricColors.sleep.awake
                            },
                            {
                              name: 'Quality',
                              type: 'spline',
                              data: sanitizeSleepQualityData(seriesSleepQuality),
                              color: metricColors.sleep.quality,
                              yAxis: 1,
                              tooltip: {
                                valueSuffix: '%'
                              }
                            }
                          ],
                        };
                        setChartOptions(multidaySleepChartData)
          
                      } else if(panelType === 'womens-health'){
          
                      } else if(panelType === 'steps') {
                        const categories = weeklyData.map((item: any) => {
                          if (item.date) {
                            return item?.date
                          } else {
                            return null
                          }
                        })
                
                        const seriesSteps = Object.values(weeklyData).map((item: any) => {
                          if (item.data) {
                            return item?.data
                          } else {
                            return null
                          }
                        })                
                
                        const multidayChartData = {
                          title: {
                            text: '',
                          },
                          plotOptions: {
                            series: {
                              label: {
                                connectorAllowed: false,
                              }
                            },
                          },
                          xAxis: {
                            ...defaultChartOptions.xAxis,
                            categories: categories,
                          },
                          series: [
                            {
                              name: 'Steps',
                              data: seriesSteps,
                              color: metricColors.activity.steps
                            },
                          ],
                        }
                        setChartOptions(multidayChartData)
          
                      } else if (panelType === 'blood-pressure'){
          
                      } else {
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }                
                
            }
            else if (selectedFilter === 'monthly') {

                if (panelType === 'heartrate'){

                    const categories = Object.keys(userDetailData.heart_rate.monthly)
                    const monthlyHeartRateAvgValues = Object.values(userDetailData.heart_rate.monthly)
                    

                    setFilterButtonStartDate(categories[0])
                    setFilterButtonEndDate(categories[categories.length - 1])

                    const multidayChartData = {
                    title: {
                        text: '',
                    },
                    plotOptions: {
                        series: {
                        label: {
                            connectorAllowed: false,
                        },
                        },
                    },
                    xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: categories,
                    },
                    series: [
                        {
                        name: 'Rest HR',
                        data: monthlyHeartRateAvgValues,
                        color: metricColors.heartRate.resting
                        }
                    ],
                    }
                    setChartOptions(multidayChartData)

                  } else if(panelType === 'o2-sats'){                    
                                
                    const monthlyO2AvgValues = Object.values(userDetailData.o2.monthly)
                    const monthlyO2AvgKeys = Object.keys(userDetailData.o2.monthly)

                    setFilterButtonStartDate(monthlyO2AvgKeys[0])
                    setFilterButtonEndDate(monthlyO2AvgKeys[monthlyO2AvgKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyO2AvgKeys,
                      },
                      series: [
                        {
                          name: 'Avg O2',
                          data: monthlyO2AvgValues,
                          color: metricColors.o2.average
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)

                  } else if(panelType === 'stress'){

                      const monthlyAvgStressValues = Object.values(userDetailData.stress.monthly)
                      const monthlyAvgStressKeys = Object.keys(userDetailData.stress.monthly)
              
                      setFilterButtonStartDate(monthlyAvgStressKeys[0])
                      setFilterButtonEndDate(monthlyAvgStressKeys[monthlyAvgStressKeys.length - 1])
              
                      const multidayChartData = {
                        title: {
                          text: '',
                        },
                        plotOptions: {
                          series: {
                            label: {
                              connectorAllowed: false,
                            },
                          },
                        },
                        xAxis: {
                          ...defaultChartOptions.xAxis,
                          categories: monthlyAvgStressKeys,
                        },
                        series: [
                          {
                            name: 'Avg Stress',
                            data: monthlyAvgStressValues,
                            color: metricColors.stress.average
                          }
                        ],
                      }
                      setChartOptions(multidayChartData)
      
                  } else if(panelType === 'body-battery'){
                                
                    const monthlyBodyBatteryHighKeys = Object.keys(userDetailData.monthly_avg_body_battery_high.monthly)
                    const monthlyBodyBatteryHighValues = Object.values(userDetailData.monthly_avg_body_battery_high.monthly)
                    const monthlyBodyBatteryLowValues = Object.values(userDetailData.monthly_avg_body_battery_low.monthly)

                    setFilterButtonStartDate(monthlyBodyBatteryHighKeys[0])
                    setFilterButtonEndDate(monthlyBodyBatteryHighKeys[monthlyBodyBatteryHighKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyBodyBatteryHighKeys,
                      },
                      series: [
                        {
                          name: 'Avg BB High',
                          data: monthlyBodyBatteryHighValues,
                          color: metricColors.bodyBattery.high
                        },
                        {
                          name: 'Avg BB Low',
                          data: monthlyBodyBatteryLowValues,
                          color: metricColors.bodyBattery.low
                        }

                      ],
                    }
                    setChartOptions(multidayChartData)

      
                  } else if(panelType === 'intensity-minutes') {
                    
                    const monthlyVigorousIntensityMinutesKeys = Object.keys(userDetailData.intensity_vigorous.monthly)
                    const monthlyModerateIntensityMinutesValues = Object.values(userDetailData.intensity_moderate.monthly)
                    const monthlyVigoruousIntensityMinutesValues = Object.values(userDetailData.intensity_vigorous.monthly)

                    setFilterButtonStartDate(monthlyVigorousIntensityMinutesKeys[0])
                    setFilterButtonEndDate(monthlyVigorousIntensityMinutesKeys[monthlyVigorousIntensityMinutesKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyVigorousIntensityMinutesKeys,
                      },
                      series: [
                        {
                          name: 'Avg Vig Int Min',
                          data: monthlyVigoruousIntensityMinutesValues,
                          color: metricColors.activity.vigorousIntensity
                        },
                        {
                          name: 'Avg Mod Int Min',
                          data: monthlyModerateIntensityMinutesValues,
                          color: metricColors.activity.moderateIntensity
                        }

                      ],
                    }
                    setChartOptions(multidayChartData)
                  } else if(panelType === 'sleep') {
                    const monthlySleepQualityKeys = Object.keys(userDetailData.monthly_avg_sleepquality.monthly)
                    const monthlySleepQualityValues = Object.values(userDetailData.monthly_avg_sleepquality.monthly)
            
                    const monthlySleepHoursAvgKeys = Object.keys(userDetailData.sleep.monthly)
                    const monthlySleepHoursAvgValues = Object.values(userDetailData.sleep.monthly)
                    setFilterButtonStartDate(monthlySleepQualityKeys[0])
                    setFilterButtonEndDate(monthlySleepQualityKeys[monthlySleepQualityKeys.length - 1])

                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlySleepQualityKeys,
                      },
                      series: [
                        {
                          name: 'Avg Quality Sleep',
                          data: monthlySleepQualityValues,
                          color: metricColors.sleep.quality
                        },
                        {
                          name: 'Avg Hours Sleep',
                          data: monthlySleepHoursAvgValues,
                          color: metricColors.sleep.hours
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)
      
                  } else if(panelType === 'womens-health'){
      
                  } else if(panelType === 'steps') {
                    const monthlyStepsAvgValues = Object.values(userDetailData.steps.monthly)
                    const monthlyStepsAvgKeys = Object.keys(userDetailData.steps.monthly)
            
                    setFilterButtonStartDate(monthlyStepsAvgKeys[0])
                    setFilterButtonEndDate(monthlyStepsAvgKeys[monthlyStepsAvgKeys.length - 1])
            
                    const multidayChartData = {
                      title: {
                        text: '',
                      },
                      plotOptions: {
                        series: {
                          label: {
                            connectorAllowed: false,
                          },
                        },
                      },
                      xAxis: {
                        ...defaultChartOptions.xAxis,
                        categories: monthlyStepsAvgKeys,
                      },
                      series: [
                        {
                          name: 'Avg Steps',
                          data: monthlyStepsAvgValues,
                          color: metricColors.activity.steps
                        }
                      ],
                    }
                    setChartOptions(multidayChartData)
      
                  } else if (panelType === 'blood-pressure'){
      
                  } else {
                    setChartOptions(defaultChartOptions)
                    console.error("Unsupported panel type provided: ", panelType)
                }   

            }

            // Set up Table Data based on Panel Type

            if (panelType === 'sleep'){
              const data: any[] = []
              let reversedFormattedDailyArr: any[] = formattedDailyArr.slice().reverse();
        
              reversedFormattedDailyArr.forEach((item: any) => {
                if (item.data) {
                  data.push([
                    item.date,
                    item.data.totalsleepduration,
                    item.data.deepsleepduration,
                    item.data.lightsleepduration,
                    item.data.remsleepduration,
                    item.data.awakeduration,
                    String(item.data.sleepquality) + "%",
                  ])
                }
              })
              setTableData(data)
            } else if(panelType === 'womens-health'){
              const womensHealth = userDetailData.women
              const data: any[] = []
        
              let isPredictedCycle: any = String(womensHealth.ispredictedcycle)
              if (womensHealth.ispredictedcycle === true) {
                isPredictedCycle = <CheckCircleIcon color="success" />
              } else if (womensHealth.ispredictedcycle === false) {
                isPredictedCycle = <CancelIcon color="error" />
              }
        
              data.push([
                womensHealth.duedate,
                womensHealth.originalduedate,
                womensHealth.periodlength,
                womensHealth.currentphase,
                womensHealth.currentphasetype,
                womensHealth.daysuntilnextphase,
                womensHealth.cyclelength,
                womensHealth.predictedcyclelength,
                womensHealth.ispredictedcycle,
                womensHealth.pregnancycyclestartdate
              ])
              setTableData(data)
            }

            

        } else {
            setChartOptions(defaultChartOptions)
        }
    }, [userDetailData, formattedDailyArr, weeklyArrStartIndex, weeklyArrEndIndex, dailyArrIndex, selectedFilter, singleDayArrowsUsed])

  
    // Implement navigation logic (handleGetNext, handleGetPrevious)
    useEffect(() => {

        let lengthOfDataArr = 0;
        if (userDetailData) {

          // Using heart_rate daily since all data summaries have same
          // amount of days in their daily arrays
          if (panelType !== 'blood-pressure'){
            lengthOfDataArr = Object.keys(userDetailData.heart_rate.daily).length            
          } else if (panelType === 'blood-pressure') {
            lengthOfDataArr = Object.keys(userDetailData.blood_pressure.daily).length
          } else {
            console.error("INFO: Unknown panel type in navigation logic")
          }

          if (selectedFilter === 'daily') {
            if ((dailyArrIndex >= (lengthOfDataArr - 1))) {
              setDisplayRightArrow(false)
            } else {
              setDisplayRightArrow(true)
            }
    
            if ((dailyArrIndex <= 0)) {
              setDisplayLeftArrow(false)
            } else {
              setDisplayLeftArrow(true)
            }
          }
          else if (selectedFilter === 'weekly') {
            if (weeklyArrEndIndex >= (lengthOfDataArr - 1)) {
              setDisplayRightArrow(false)
            } else {
              setDisplayRightArrow(true)
            }
    
            if (weeklyArrStartIndex <= 0) {
              setDisplayLeftArrow(false)
            } else {
              setDisplayLeftArrow(true)
            }
          }    
        }    
      }, [dailyArrIndex, weeklyArrStartIndex, weeklyArrEndIndex, displayLeftArrow, displayRightArrow, selectedFilter])


    // Logging for debug purposes
    // useEffect(() => {
    //   console.log('Organized columns:', organizeRangesIntoColumns(ranges));
    //   console.log('Total ranges:', ranges.length);
    // }, [ranges]);


    return (
      <>
        {/* Render SelectedTimeline, ChartFilterButtons, and Chart components */}

        {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <SelectedTimeline
              selectedFilter={selectedFilter}
              customRangeStartDate={startDateValue}
              customRangeEndDate={endDateValue}
              dateRangeStartDate={filterButtonStartDate ? filterButtonStartDate : dateRangeArrowsBeginDate}
              dateRangeEndDate={filterButtonEndDate ? filterButtonEndDate : dateRangeArrowsEndDate}
              singleDay={singleDayArrowsUsed}
            />
            )
          }

          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <ChartFilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSingleDayArrowsUsed={setSingleDayArrowsUsed}
              setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
              setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setSingleDayDecrementDays={setSingleDayDecrementDays}
              setDateRangeDecrementDays={setDateRangeDecrementDays}
              setFilterButtonStartDate={setFilterButtonStartDate}
              setFilterButtonEndDate={setFilterButtonEndDate}
              setChartData={setChartOptions}
              showDailyFilter={showDailyFilter}
              showWeeklyFilter={true}
              showMonthlyFilter={true}
              showCustomFilter={false}
              numberOfUnits={numberOfUnits}
              setNumberOfUnits={setNumberOfUnits}
              avgType={avgType}
              setAvgType={setAvgType}
              btnPressed={btnPressed}
              setBtnPressed={setBtnPressed}
            />
            )
          }

          {
            selectedFilter == 'daily' &&
            (
              <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={clearRangeSelections}
            disabled={ranges.length === 0}
          >
            Clear Selections
          </Button>
            )
            
          }
          

          {/* Exclude Panel Arrows and chart from Women's Health Panel, but include for all other panels */}
          {
            panelType !== 'womens-health' && panelType !== 'blood-pressure' && (
              <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
              {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
                <Grid item={true} xs={1}>
                  <IconButton onClick={handleGetPrevious}
                    style={{ visibility: displayLeftArrow ? 'visible' : 'hidden' }}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
              )}
  
              <Grid item={true} xs={10}>
                  <GenericChart chartOptions={chartOptions} />
              </Grid>
  
              {selectedFilter !== 'custom' && selectedFilter !== 'monthly' && (
                <Grid item={true} xs={1}>
                  <IconButton onClick={handleGetNext}
                    style={{ visibility: displayRightArrow ? 'visible' : 'hidden' }}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid>

                {/* {
                  selectedFilter == 'daily' &&
                  (
                    
                    ranges.length > 0 && (

                      <Paper elevation={1} sx={{ p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          Selected Ranges Analysis
                        </Typography> */}


                        {/* Previous Way */}
                        {/* <Box
                        sx={{
                          display: 'grid',
                          gridAutoFlow: 'row',
                          gridTemplateColmns: `repeat(3 1fr))`,
                          gap: 2,
                          width: '100%'
                        }}>
                          {ranges.map((range, idx) => (
                                  <Box key={range.plotBandId}>
                                    <ListItem
                                      key={range.plotBandId}
                                      sx={{
                                        // Highlight ranges that belong to the current day
                                        backgroundColor: range.dateIdentifier === currentDateIdentifier 
                                        ? 'rgba(33, 150, 243, 0.05)' 
                                        : 'transparent'
                                      }}>
                                      <ListItemText
                                        primary={
                                          range.startDate === range.endDate ?
                                            `${range.startDate}: ${range.start} - ${range.end}` :
                                            `${range.startDate} ${range.start} - ${range.endDate} ${range.end}`
                                        }
                                        secondary={`Average: ${range.average.toFixed(1)}`}
                                      />
                                      <ListItemSecondaryAction>
                                        <IconButton 
                                          edge="end" 
                                          aria-label="delete" 
                                          size="small"
                                          onClick={() => removeRange(range.plotBandId)}
                                        >
                                          <ClearIcon color="error" />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                    {idx < ranges.length - 1 && <Divider />}
                                  </Box>
                                ))}
                        </Box> */}



{/* 
                      </Paper>
                    )
                  )
                } */}



                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    width: '100%',
                    mt: 2  // margin top for spacing between chart and list
                  }}>
                    <Grid 
                      container 
                      spacing={2}  // spacing between columns
                      sx={{ 
                        maxWidth: '100%',
                        justifyContent: 'flex-start'  // start from left
                      }}
                    >
                      {organizeRangesIntoColumns(ranges).map((column, columnIndex) => (
                        
                          // Each column gets its own List component
                          <List 
                            key={columnIndex}
                            dense 
                            sx={{
                              width: '300px',  // fixed width for each column
                              flex: '0 0 auto'   // prevents columns from stretching
                            }}
                          >
                            {column.map((range) => (
                              <ListItem 
                                key={range.plotBandId}
                                sx={{
                                  backgroundColor: range.dateIdentifier === currentDateIdentifier 
                                    ? 'rgba(33, 150, 243, 0.05)' 
                                    : 'transparent',
                                  borderRadius: 1,  // slightly rounded corners
                                  mb: 1  // margin bottom between items
                                }}
                              >
                                <ListItemText
                                  primary={
                                    range.startDate === range.endDate ?
                                      `${range.startDate}: ${range.start} - ${range.end}` :
                                      `${range.startDate} ${range.start} - ${range.endDate} ${range.end}`
                                  }
                                  secondary={`Average: ${range.average.toFixed(1)}`}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton 
                                    edge="end" 
                                    aria-label="delete" 
                                    size="small"
                                    onClick={() => removeRange(range.plotBandId)}
                                  >
                                    <ClearIcon color="error" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        
                      )
                      )
                      
                      
                      }
                    </Grid>
                  </Box>




              </Grid>
            </Grid>
            )
          }
          {
            panelType === 'sleep' && selectedFilter !== 'custom' && (
              <MUIDataTable title="" data={tableData} columns={tableColumns} options={tableOptions} />
            )
          }

          {/* Women's Health Panel */}
          {
            panelType === 'womens-health' && (
              <MUIDataTable title="Women's Health Data" data={tableData} columns={tableColumns} options={tableOptions} />
            )
          }

          {/* Blood Pressure Panel */}
          {panelType === 'blood-pressure' && (
              <ChartFilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSingleDayArrowsUsed={setSingleDayArrowsUsed}
              setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
              setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setSingleDayDecrementDays={setSingleDayDecrementDays}
              setDateRangeDecrementDays={setDateRangeDecrementDays}
              setFilterButtonStartDate={setFilterButtonStartDate}
              setFilterButtonEndDate={setFilterButtonEndDate}
              setChartData={setChartOptions}
              showDailyFilter={showDailyFilter}
              showWeeklyFilter={false}
              showMonthlyFilter={false}
              showCustomFilter={false}
              numberOfUnits={numberOfUnits}
              setNumberOfUnits={setNumberOfUnits}
              avgType={avgType}
              setAvgType={setAvgType}
              btnPressed={btnPressed}
              setBtnPressed={setBtnPressed}
            />
            )}
          { panelType === 'blood-pressure' && (
            <Grid container={true} alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
              {                
                <MUIDataTable title="Blood Pressure Data" data={tableData} columns={tableColumns} options={tableOptions} />                
              }
            </Grid>
            )
          }
        </>
      )}

      </>
    )
  }
  
  export default GenericPanelContent