import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ChartFilterButtons from '../../../components/chart-filter-buttons'
import GenericChart from '../../../components/chart'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton, { iconButtonClasses } from '@mui/material/IconButton'
import SelectedTimeline from '../../../components/selected-timeline'
import { metricColors, chartColors } from '../../../helpers/constants'

  
const metricKeys = ['heartrate', 'o2Sats', 'stress', 'bodyBattery', 
    'intensityMinutes', 'sleep', 'steps'] as const;
  type MetricKey = typeof metricKeys[number]


  interface MetricDataPoint {
    date: string;
    data: any;  // or be more specific based on your data structure
  }
  interface MetricData {
    daily: MetricDataPoint[];
    weekly: MetricDataPoint[];
  }

  type FormattedData = Record<MetricKey, MetricData>;

  interface IProps {
    selectedUser: IUserInfo | null
    userDetailData: IUserDetailData | null    
    filterType: IChartFilter
  }

  interface ChartState {
    dailyArrIndex: number
    weeklyArrStartIndex: number
    weeklyArrEndIndex: number
    displayLeftArrow: boolean
    displayRightArrow: boolean
  }

  interface PanelDates {
    startDate: string,
    endDate: string,
    singleDay: string
  }

  const defaultChartOptions = {
    title: { text: '' },
    xAxis: { categories: [] },
    plotOptions: {
      series: {
        label: { connectorAllowed: false }
      }
    },
    series: [{ name: '', data: [] }]
  }

  const OverviewPanelContent: React.FC<IProps> = ({ selectedUser, userDetailData, filterType}) => {
    
    const [selectedFilter, setSelectedFilter] = useState<IChartFilter | null>('weekly')  
    const [formattedData, setFormattedData] = useState<FormattedData>({
        heartrate: { daily: [], weekly: [] },
        o2Sats: { daily: [], weekly: [] },
        stress: { daily: [], weekly: [] },
        bodyBattery: { daily: [], weekly: [] },
        intensityMinutes: { daily: [], weekly: [] },
        sleep: { daily: [], weekly: [] },
        steps: { daily: [], weekly: [] }
    })
    const [chartState, setChartState] = useState<ChartState>({
        dailyArrIndex: 0,
        weeklyArrStartIndex: 0,
        weeklyArrEndIndex: 0,
        displayLeftArrow: true,
        displayRightArrow: false
      })
    const [chartOptions, setChartOptions] = useState<any>(defaultChartOptions)
    const [dateRange, setDateRange] = useState<PanelDates>({
        startDate: '-',
        endDate: '-',
        singleDay: '-'
      } )

      const [filterButtonStartDate, setFilterButtonStartDate] = useState<string | null>(null)
      const [filterButtonEndDate, setFilterButtonEndDate] = useState<string | null>(null)
      const [singleDayArrowsUsed, setSingleDayArrowsUsed] = useState<string | null>(null)
      const [singleDayDecrementDays, setSingleDayDecrementDays] = useState<boolean | null>(null)
      const [dateRangeArrowsBeginDate, setDateRangeArrowsBeginDate] = useState<string | null>(null)
      const [dateRangeArrowsEndDate, setDateRangeArrowsEndDate] = useState<string | null>(null)
      const [dateRangeDecrementDays, setDateRangeDecrementDays] = useState<boolean | null>(null)
      const [tableData, setTableData] = useState<any[]>([])

      const [startDateValue, setStartDateValue] = useState<Date | any>(null)
      const [endDateValue, setEndDateValue] = useState<Date | any>(null)
      const [numberOfUnits, setNumberOfUnits] = useState<number | null>(0)
      const [avgType, setAvgType] = useState<string | null>('')
      const [btnPressed, setBtnPressed] = useState<boolean | null>(null)
  

    // Constants
    const numOfWeeklyDays = 28

    // Helper functions for data processing
    const processMetricData = (data: any, processor: (item: any) => any) => {
        return Object.keys(data).map(key => ({
        date: key,
        data: processor(data[key])
        }))
    }

    // This is for checking the type of the data coming in when needed
    function isDailyHeartRateData(data: any): data is HeartRateData {
      return typeof data === 'object' && data !== null && 'timeoffsetheartratesamples' in data;
    }
    function isO2Data(data: any): data is O2Data {
      return typeof data === 'object' && data !== null && 'timeoffsetsleepspo2' in data;
    }
    function isStress(data: any): data is StressData {
      return typeof data === 'object' && data !== null && 'timeoffsetstresslevelvalues' in data;
    }
    function isBodyBattery(data: any): data is BodyBatteryData {
      return typeof data === 'object' && data !== null && 'timeoffsetbodybatteryvalues' in data;
    }
    function isIntensityMinutes(data: any): data is IntensityMinutesData {
      return typeof data === 'object' && data !== null && 'vigorousintensitydurationinseconds' in data && 'moderateintensitydurationinseconds' in data;
    }
    function isSleep(data: any): data is SleepData {
      return typeof data === 'object' && data !== null && 'sleeplevelsmap' in data;
    }
    function isStepsData(data: any): data is Steps {
      return data !== null && typeof data !== "string";
    }
    function isInteger(value: any): boolean {
      return typeof value === 'number' && Number.isInteger(value);
  }
  
  // Process initial data
  useEffect(() => {

    if (!userDetailData) return

    const processData = () => {
        const processedData = {
          heartrate: {
            daily: processMetricData(userDetailData.heart_rate.daily, 
              (data) => isDailyHeartRateData(data) ? data : null),
            weekly: [] as any[]
          },
          o2Sats: {
            daily: processMetricData(userDetailData.o2.daily,
              (data) => isO2Data(data) ? data : null),
            weekly: [] as any[]
          },
          stress: {
            daily: processMetricData(userDetailData.stress.daily,
                (data) => isStress(data) ? data : null),
            weekly: [] as any[]      
          },
          bodyBattery: {
            daily: processMetricData(userDetailData.body_battery.daily,
                (data) => isBodyBattery(data) ? data : null),
            weekly: [] as any[]        
          },
          intensityMinutes: {
            daily: processMetricData(userDetailData.intensity.daily,
                (data) => isIntensityMinutes(data) ? data : null),
            weekly: [] as any[]        
          },
          sleep: {
            daily: processMetricData(userDetailData.sleep.daily,
                (data) => isSleep(data) ? data : null),
            weekly: [] as any[]     
          },
          steps: {
            daily: processMetricData(userDetailData.steps.daily,
                (data) => isStepsData(data) ? data : null),
            weekly: [] as any[]   
          },
        }

        // Calculate initial indices
        const dataLength = processedData.heartrate.daily.length
        const lastIndex = dataLength - 1

        setChartState(prev => ({
            ...prev,
            dailyArrIndex: lastIndex,
            weeklyArrStartIndex: lastIndex - numOfWeeklyDays,
            weeklyArrEndIndex: lastIndex
        }))

        console.log("numOfWeeklyDays: ", numOfWeeklyDays)

        metricKeys.forEach((metric: MetricKey) => {
            processedData[metric].weekly = processedData[metric].daily.slice(
                lastIndex - numOfWeeklyDays,
                lastIndex + 1
            )
        })
        return processedData        
      }
      setFormattedData(processData())
  }, [userDetailData])


  // Handle chart updates
  useEffect(() => {
    if (!formattedData || !selectedFilter) return

    const updateChartOptions = () => {
      if (selectedFilter === 'daily') {
        return createDailyChartOptions()
      } else if (selectedFilter === 'weekly') {
        return createWeeklyChartOptions()
      } else {
        return createMonthlyChartOptions()
      }
    }

    setChartOptions(updateChartOptions())
  }, [formattedData, selectedFilter, chartState.dailyArrIndex, 
      chartState.weeklyArrStartIndex, chartState.weeklyArrEndIndex])


// Chart options creators
const createDailyChartOptions = () => {
    const { dailyArrIndex } = chartState;
    const dailyData = formattedData.heartrate.daily[dailyArrIndex];
  
    // Update date range
    setDateRange({
      startDate: '-',
      endDate: '-',
      singleDay: dailyData?.date
    });
  
    // Get categories and series data for each metric
    const heartrateCategories = isDailyHeartRateData(dailyData?.data)
      ? Object.values(dailyData.data.timeoffsetheartratesamples).map((item: any) =>
          new Date(parseInt(item?.timestamp) * 1000).toLocaleTimeString())
      : [];
  
    const heartrateSeries = isDailyHeartRateData(dailyData?.data)
      ? Object.values(dailyData.data.timeoffsetheartratesamples).map((item: any) => item?.value)
      : [];
  
    const o2SatsData = formattedData.o2Sats.daily[dailyArrIndex]?.data;
    const o2SatsSeries = isO2Data(o2SatsData)
      ? Object.values(o2SatsData.timeoffsetsleepspo2).map((item: any) => item?.value)
      : [];
  
    const stressData = formattedData.stress.daily[dailyArrIndex]?.data;
    const stressSeries = isStress(stressData)
      ? Object.values(stressData.timeoffsetstresslevelvalues).map((item: any) => item?.value)
      : [];
  
    const bodyBatteryData = formattedData.bodyBattery.daily[dailyArrIndex]?.data;
    const bodyBatterySeries = isBodyBattery(bodyBatteryData)
      ? Object.values(bodyBatteryData.timeoffsetbodybatteryvalues).map((item: any) => item?.value)
      : [];
  
    return {
      ...defaultChartOptions,
      xAxis: {
        ...defaultChartOptions.xAxis,
        categories: heartrateCategories
      },
      series: [
        {
          name: 'Heart Rate',
          data: heartrateSeries,
          color: metricColors.heartRate.resting
        },
        {
          name: 'O2 Sats',
          data: o2SatsSeries,
          color: metricColors.o2.average
        },
        {
          name: 'Stress',
          data: stressSeries,
          color: metricColors.stress.average
        },
        {
          name: 'Body Battery',
          data: bodyBatterySeries,
          color: metricColors.bodyBattery.high
        }
      ]
    };
  };

const createWeeklyChartOptions = () => {
    const { weeklyArrStartIndex, weeklyArrEndIndex } = chartState;
    const weeklyData = {
      heartrate: formattedData.heartrate.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      o2Sats: formattedData.o2Sats.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      stress: formattedData.stress.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      bodyBattery: formattedData.bodyBattery.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      steps: formattedData.steps.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      intensityMinutes: formattedData.intensityMinutes.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1),
      sleep: formattedData.sleep.daily.slice(weeklyArrStartIndex, weeklyArrEndIndex + 1)
    };
  
    // Update date range
    setDateRange({
      startDate: weeklyData.heartrate[0]?.date,
      endDate: weeklyData.heartrate[weeklyData.heartrate.length - 1]?.date,
      singleDay: '-'
    });
  
    // Process series data
    const categories = weeklyData.heartrate.map(item => item.date);
    
    const heartrateAvg = weeklyData.heartrate.map(item => 
      item.data?.averageheartrateinbeatsperminute || null
    );
    
    const heartrateMax = weeklyData.heartrate.map(item => 
      item.data?.maxheartrateinbeatsperminute || null
    );
    
    const heartrateResting = weeklyData.heartrate.map(item => 
      item.data?.restingheartrateinbeatsperminute || null
    );
    
    const stressAvg = weeklyData.stress.map(item => 
        item.data?.averagestresslevel || null
    );   

    const stepsTotal = weeklyData.steps.map(item => 
        item?.data || null
    );
  
    const o2SatsAvg = weeklyData.o2Sats.map(item => 
      item.data?.avg || null
    );

    const o2SatsLow = weeklyData.o2Sats.map(item => 
        item.data?.low || null
      );

    const moderateIntensityMinutes = weeklyData.intensityMinutes.map(item => 
        item.data?.moderateintensitydurationinseconds || null
    );

    const vigorousIntensityMinutes = weeklyData.intensityMinutes.map(item => 
        item.data?.vigorousintensitydurationinseconds || null
    );

    const deepSleep = weeklyData.sleep.map((item: any) => {

      if (item.data && item.data.deepsleepdurationinseconds != "-") {
        return { 
          y: item?.data?.deepsleepdurationinhours,
          additionalField: item?.data?.deepsleepduration
        }          
      } else {
        return { 
          y: 0,
          additionalField: "-"
        }   
      }

    } 
       
    );

    const remSleep = weeklyData.sleep.map((item: any) => {
      if (item.data && item.data.remsleepdurationinseconds != "-") {
        return { 
          y: item?.data?.remsleepdurationinhours,
          additionalField: item?.data?.remsleepduration
        }          
      } else {
        return { 
          y: 0,
          additionalField: "-"
        }   
      }
    } 
    );

    const lightSleep = weeklyData.sleep.map((item: any) =>{
      if (item.data && item.data.lightsleepdurationinseconds != "-") {
        return { 
          y: item?.data?.lightsleepdurationinhours,
          additionalField: item?.data?.lightsleepduration
        }          
      } else {
        return { 
          y: 0,
          additionalField: "-"
        }   
      }
    } 

    );

    const awakeSleep = weeklyData.sleep.map((item: any) => {

      if (item.data && item.data.awakedurationinseconds != "-") {
        return { 
          y: item?.data?.awakedurationinhours,
          additionalField: item?.data?.awakeduration
        }          
      } else {
        return { 
          y: 0,
          additionalField: "-"
        }   
      }
    });

    const totalSleepHours = weeklyData.sleep.map((item: any) => {
      if (item.data && item.data.totalsleepdurationindseconds != "-") {
        return { 
          y: item?.data?.totalsleepdurationinhours,
          additionalField: item?.data?.totalsleepduration
        }          
      } else {
        return { 
          y: 0,
          additionalField: "-"
        }   
      }
    })

    const sleepQuality = weeklyData.sleep.map(item => 
        item.data?.sleepquality || null
    );  

    const bodyBatteryHigh = weeklyData.bodyBattery.map(item => 
      item.data?.high || null
    );

    const bodyBatteryLow = weeklyData.bodyBattery.map(item => 
        item.data?.low || null
    );

    return {
      title: {
        text: '',
      },
      chart: {
        marginRight: 150
      },
      yAxis: [
        { 
          title: { text: 'HR | O2 | Stress | BB' },
          min: 0
        },
        { // Steps axis
            title: { text: 'Steps' },
            style: {
              display: 'none'  // Hidden by default unless series is visible
            },
            opposite: false,
            min: 0
        },
        { // Sleep Hours axis
        title: { text: 'Sleep' },
        style: {
            display: 'none'  // Hidden by default unless series is visible
        },
        opposite: false,
        min: 0,
        
        gridLineWidth: 0
        },
        { // Intensity Minutes axis
        title: { text: 'Intensity Minutes' },
        style: {
            display: 'none'  // Hidden by default unless series is visible
        },
        opposite: false,
        min: 0,
        gridLineWidth: 0
        }
      ],
      legend: {
        enabled: true,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        floating: true
      },
      tooltip: {
        formatter: function(this: Highcharts.TooltipFormatterContextObject): string {

          const value = this.y;
          const sleepMetricNames = ['Total Sleep', 'Awake Sleep', 'Light Sleep', 'REM Sleep', 'Deep Sleep']

          if (sleepMetricNames.includes(this.series.name)) {
            const additionalData = (this.point as any).additionalField;
            if (typeof value !== 'number') {
              return `${this.series.name}: N/A`;
            } 
            return `${this.series.name}: ${additionalData}`;
          }
          return `${this.series.name}: ${value}`;
        }
      },
      xAxis: {
        categories: categories
      },
      series: [
        {
          name: 'Avg HR',
          yAxis:0,
          data: heartrateAvg,
          color: metricColors.heartRate.average,
          visible: true
        },
        {
          name: 'Max HR',
          yAxis: 0,
          data: heartrateMax,
          color: metricColors.heartRate.max,
          visible: false
        },
        {
          name: 'Rest HR',
          yAxis: 0,
          data: heartrateResting,
          color: metricColors.heartRate.resting,
          visible: false
        },
        {
          name: 'Avg O2',
          yAxis: 0,
          data: o2SatsAvg,
          color: metricColors.o2.average,
          visible: false
        },
        {
            name: 'Low O2',
            data: o2SatsLow,
            type: 'line',
            yAxis: 0,
            visible: false,
            color: metricColors.o2.low
        },
        {
          name: 'Avg Stress',
          yAxis: 0,
          data: stressAvg,
          color: metricColors.stress.average,
          visible: true
        },
        {
            name: 'Steps',
            data: stepsTotal,
            type: 'column',
            yAxis: 1,
            opacity: 0.8,
            visible: false,
            color: metricColors.activity.steps
        },
        {
          name: 'BB High',
          data: bodyBatteryHigh,
          color: metricColors.bodyBattery.high,
          visible: false
        },
        {
            name: 'BB Low',
            data: bodyBatteryLow,
            color: metricColors.bodyBattery.low,
            visible: false
        },
        {
            name: 'Deep Sleep',
            data: deepSleep,
            type: 'spline',
            yAxis: 2,
            visible: false,
            color: metricColors.sleep.deep
        },
        {
            name: 'Light Sleep',
            data: lightSleep,
            type: 'spline',
            yAxis: 2,
            visible: false,
            color: metricColors.sleep.light
        },
        {
            name: 'REM Sleep',
            data: remSleep,
            type: 'spline',
            yAxis: 2,
            visible: false,
            color: metricColors.sleep.rem
        },
        {
            name: 'Awake Sleep',
            data: awakeSleep,
            type: 'spline',
            yAxis: 2,
            visible: false,
            color: metricColors.sleep.awake
        },
        {
          name: 'Total Sleep',
          data: totalSleepHours,
          type: 'spline',
          yAxis: 2,
          visible: false,
          color: metricColors.sleep.hours
      },
        {
            name: 'Quality Sleep',
            data: sleepQuality,
            type: 'line',
            yAxis: 0,
            visible: false,
            color: metricColors.sleep.quality
        },
        {
            name: 'Mod Intensity',
            data: moderateIntensityMinutes,
            type: 'area',
            yAxis: 3,
            fillOpacity: 0.3,
            visible: false,
            color: metricColors.activity.moderateIntensity
        },
        {
            name: 'Vig Intensity',
            data: vigorousIntensityMinutes,
            type: 'area',
            yAxis: 3,
            fillOpacity: 0.3,
            visible: false,
            color: metricColors.activity.vigorousIntensity
        }
      ]
    };
  };


const createMonthlyChartOptions = () => {
    if (!userDetailData) return defaultChartOptions;
  
    const categories = Object.keys(userDetailData.heart_rate.monthly);
    const monthlyHeartRateAvg = Object.values(userDetailData.heart_rate.monthly);
    const monthlyO2Avg = Object.values(userDetailData.o2.monthly);
    const monthlyStressAvg = Object.values(userDetailData.stress.monthly);
    const monthlyBodyBatteryHigh = Object.values(userDetailData.monthly_avg_body_battery_high.monthly);
    const monthlyBodyBatteryLow = Object.values(userDetailData.monthly_avg_body_battery_low.monthly);
    const monthlyModIntensityMinutes = Object.values(userDetailData.intensity_moderate.monthly);
    const monthlyVigIntensityMinutes = Object.values(userDetailData.intensity_vigorous.monthly);
    const monthlySleepQualityAvg = Object.values(userDetailData.monthly_avg_sleepquality.monthly);
    const monthlyStepsAvg = Object.values(userDetailData.steps.monthly);
    const monthlySleepHours = Object.values(userDetailData.sleep.monthly)
  
    setDateRange({
      startDate: categories[0],
      endDate: categories[categories.length - 1],
      singleDay: '-'
    });
  
    return {
      title: {
        text: ''
      },
      yAxis: [
        { // Heart Rate, O2, Stress axis 
          title: { text: 'HR | O2 | Stress | BB' },
          style: {
            display: 'none'  // Hidden by default unless series is visible
          },
          min: 0                    
        },
        { // Steps axis
          title: { text: 'Steps' },
          style: {
            display: 'none'  // Hidden by default unless series is visible
          },
          opposite: false,
          min: 0
        },
        { // Sleep Hours axis
          title: { text: 'Sleep Hours' },
          style: {
            display: 'none'  // Hidden by default unless series is visible
          },
          opposite: false,
          min: 0,
          
          gridLineWidth: 0
        },
        { // Intensity Minutes axis
          title: { text: 'Intensity Minutes' },
          style: {
            display: 'none'  // Hidden by default unless series is visible
          },
          opposite: false,
          min: 0,
          gridLineWidth: 0
        }
      ],
      legend: {
        enabled: true,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      },
      xAxis: {
        categories: categories
      },
      series: [
        {
          name: 'Rest HR',
          yAxis: 0,
          data: monthlyHeartRateAvg,
          color: metricColors.heartRate.resting
        },
        {
          name: 'Avg O2',
          yAxis: 0,
          data: monthlyO2Avg,
          color: metricColors.o2.average
        },
        {
          name: 'Avg Stress',
          yAxis: 0,
          data: monthlyStressAvg,
          color: metricColors.stress.average
        },
        {
          name: 'Avg BB High',
          yAxis: 0,
          data: monthlyBodyBatteryHigh,
          color: metricColors.bodyBattery.high
        },
        {
            name: 'Avg BB Low',
            data: monthlyBodyBatteryLow,
            type: 'line',
            yAxis: 0,
            visible: false,
            color: metricColors.bodyBattery.low
            
          },
          {
            name: 'Avg Mod Int Min',
            data: monthlyModIntensityMinutes,
            type: 'area',
            yAxis: 3,
            fillOpacity: 0.3,
            visible: false,
            color: metricColors.activity.moderateIntensity
          },
          {
            name: 'Avg Vig Int Min',
            data: monthlyVigIntensityMinutes,
            type: 'area',
            yAxis: 3,
            fillOpacity: 0.3,
            visible: false,
            color: metricColors.activity.vigorousIntensity
            
          },
          {
            name: 'Avg Quality Sleep',
            data: monthlySleepQualityAvg,
            type: 'line',
            yAxis: 0,
            visible: false,
            color: metricColors.sleep.quality
            
          },                  
          {
            name: 'Avg Steps',
            data: monthlyStepsAvg,
            type: 'column',
            yAxis: 1,
            opacity: 0.8,
            visible: false,
            color: metricColors.activity.steps
            
          },
          {
            name: 'Avg Hours Sleep',
            data: monthlySleepHours,
            type: 'spline',
            yAxis: 2,
            visible: false,
            color: metricColors.sleep.hours
          }
      ]
    };
  };


  // Handle Chart Navigation
  const handleGetNext = () => {
    if (!userDetailData) return

    setChartState(prev => {
      const dataLength = formattedData.heartrate.daily.length
      
      if (selectedFilter === 'daily') {
        const newIndex = prev.dailyArrIndex + 1
        return {
          ...prev,
          dailyArrIndex: newIndex,
          displayRightArrow: newIndex < dataLength - 1,
          displayLeftArrow: true
        }
      } else if (selectedFilter === 'weekly') {
        const newEndIndex = prev.weeklyArrEndIndex + numOfWeeklyDays
        let newStartIndex = prev.weeklyArrStartIndex + numOfWeeklyDays

        if (newEndIndex > dataLength - 1) {
            newStartIndex = (dataLength - 1) - 28
        }

        return {
          ...prev,
          weeklyArrEndIndex: Math.min(newEndIndex, dataLength - 1),
          weeklyArrStartIndex: newStartIndex,
          displayRightArrow: newEndIndex < dataLength - 1,
          displayLeftArrow: true
        }
      } 
    return prev
    })
  }

  const handleGetPrevious = () => {
    if (!userDetailData) return;
    
    setChartState(prev => {       
      if (selectedFilter === 'daily') {
        const newIndex = prev.dailyArrIndex - 1;
        return {
          ...prev,
          dailyArrIndex: newIndex,
          displayLeftArrow: newIndex > 0,
          displayRightArrow: true
        };
      } else if (selectedFilter === 'weekly') {
        const newStartIndex = prev.weeklyArrStartIndex - numOfWeeklyDays;
        let newEndIndex = prev.weeklyArrEndIndex - numOfWeeklyDays;

        if (newStartIndex < 0){
            newEndIndex = numOfWeeklyDays
        } 
        return {
          ...prev,
          weeklyArrStartIndex: Math.max(newStartIndex, 0),
          weeklyArrEndIndex: newEndIndex,
          displayLeftArrow: newStartIndex > 0,
          displayRightArrow: true
        };
      }

      return prev;  
    });
  };


  return (
    <>
      {!selectedUser ? (
        <Typography variant="body2">Please search for user first</Typography>
      ) : (
        <>
          <SelectedTimeline
            selectedFilter={selectedFilter}
            dateRangeStartDate={dateRange.startDate}
            dateRangeEndDate={dateRange.endDate}
            singleDay={dateRange.singleDay}
          />
          
          <ChartFilterButtons
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSingleDayArrowsUsed={setSingleDayArrowsUsed}
              setDateRangeArrowsBeginDate={setDateRangeArrowsBeginDate}
              setDateRangeArrowsEndDate={setDateRangeArrowsEndDate}
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setSingleDayDecrementDays={setSingleDayDecrementDays}
              setDateRangeDecrementDays={setDateRangeDecrementDays}
              setFilterButtonStartDate={setFilterButtonStartDate}
              setFilterButtonEndDate={setFilterButtonEndDate}
              setChartData={setChartOptions}
              showDailyFilter={false}
              showWeeklyFilter={true}
              showMonthlyFilter={true}
              showCustomFilter={false}
              numberOfUnits={numberOfUnits}
              setNumberOfUnits={setNumberOfUnits}
              avgType={avgType}
              setAvgType={setAvgType}
              btnPressed={btnPressed}
              setBtnPressed={setBtnPressed}
            />
          
          <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ marginTop: '10px' }}>
            {selectedFilter !== 'monthly' && (
              <Grid item xs={1}>
                <IconButton 
                  onClick={handleGetPrevious}
                  style={{ visibility: chartState.displayLeftArrow ? 'visible' : 'hidden' }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item xs={10}>
              <GenericChart chartOptions={chartOptions} />
            </Grid>

            {selectedFilter !== 'monthly' && (
              <Grid item xs={1}>
                <IconButton 
                  onClick={handleGetNext}
                  style={{ visibility: chartState.displayRightArrow ? 'visible' : 'hidden' }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

  export default OverviewPanelContent